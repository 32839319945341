/* DEFAULT */
article, div, footer, header, nav, section, fieldset, input, select, textarea { box-sizing: border-box; display: block; position: relative; }
ol, ul, li { box-sizing: border-box; display: block; list-style-type: none; margin: 0; padding: 0; position: relative; }
h1, h2, h3, p { display: block; margin: 0; }
a { color: #333; cursor: pointer; text-decoration-color: rgba(51,51,51,0.5); }
img { border: none; margin: 0; padding: 0; }
textarea { font-family: 'Lato', sans-serif; }
form { display: inline; }

/* COMMON */
.ee-align-left { text-align: left !important; }
.ee-align-center { text-align: center !important; }
.ee-align-right { text-align: right !important; }
.ee-align-top { align-items: flex-start !important; }
.ee-align-middle { align-items: center !important; }
.ee-align-bottom { align-items: flex-end !important; }
.ee-align-cover { align-items: stretch !important; }




/* MAIN SECTION */
.e-mainsection { display: block; position: relative; width: 100%; }
.e-mainsection:before { content: ''; display: block; height: 1px; margin: -1px 0 0 0; width: 100%; }
.e-mainsection.ee-tint:after { background: #f3f3f3; border-bottom: 1px solid #e6e6e6; border-top: 1px solid #e6e6e6; box-sizing: border-box; content: ''; display: block; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -2; }

.e-mainrow { align-items: stretch; display: inline-flex; flex-flow: row wrap; justify-content: flex-start; margin: 0 auto; position: relative; width: 100%; }
.e-mainrow.ee-width-s { max-width: 480px; }
.e-mainrow.ee-width-m { max-width: 720px; }
.e-mainrow.ee-width-l { max-width: 960px; }
.e-mainrow.ee-width-xl { max-width: 1200px; }

.e-maincol { display: inline-block; padding: 0 20px; position: relative; vertical-align: top; width: 100%; }
.e-maincol:before { content: ''; display: block; height: 1px; margin: -1px 0 0 0; width: 100%; }
.e-maincol.ee-width-25 { width: 25%; }
.e-maincol.ee-width-33 { width: 33.3%; }
.e-maincol.ee-width-50 { width: 50%; }
.e-maincol.ee-width-66 { width: 66.7%; }
.e-maincol.ee-width-75 { width: 25%; }
@media screen and (min-width: 480px) {
.e-maincol.es-width-25 { width: 25%; }
.e-maincol.es-width-33 { width: 33.3%; }
.e-maincol.es-width-50 { width: 50%; }
.e-maincol.es-width-66 { width: 66.7%; }
.e-maincol.es-width-75 { width: 25%; }
}
@media screen and (min-width: 720px) {
.e-maincol.em-width-25 { width: 25%; }
.e-maincol.em-width-33 { width: 33.3%; }
.e-maincol.em-width-50 { width: 50%; }
.e-maincol.em-width-66 { width: 66.7%; }
.e-maincol.em-width-75 { width: 75%; }
}
@media screen and (min-width: 960px) {
.e-maincol.el-width-25 { width: 25%; }
.e-maincol.el-width-33 { width: 33.3%; }
.e-maincol.el-width-50 { width: 50%; }
.e-maincol.el-width-66 { width: 66.7%; }
.e-maincol.el-width-75 { width: 75%; }
}

.e-mainsection.ee-pad { padding: 20px 20px 40px 20px; }
.e-mainsection.ee-pad .e-mainrow  { margin: 0 -20px; width: calc(100% + 40px); }
.e-mainsection.ee-pad .e-maincol { padding: 0 20px; }

.e-mainsection.ee-box { padding: 20px; }
.e-mainsection.ee-box .e-mainrow { gap: 20px 0; padding: 0; width: 100%; }
.e-mainsection.ee-box .e-mainrow.ee-pad { padding: 0 0 20px 0; }
.e-mainsection.ee-box .e-mainrow .e-maincol { padding: 0 20px 20px 20px; }
.e-mainsection.ee-box .e-mainrow .e-maincol:after { background: #fff; border-radius: 3px; box-shadow: inset 0 0 0 2px #e6e6e6; content: ''; display: block; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; }

@media screen and (min-width: 720px) {
.e-mainsection.ee-pad { padding: 20px 40px 40px 40px; }
.e-mainsection.ee-box { padding: 20px 40px; }
.e-mainsection.ee-box .e-mainrow  { gap: 0; margin: 0 -40px; width: calc(100% + 80px); }
.e-mainsection.ee-box .e-mainrow.ee-pad { padding: 0 20px; }
.e-mainsection.ee-box .e-mainrow .e-maincol { padding: 20px 40px 40px 40px; }
.e-mainsection.ee-box .e-mainrow .e-maincol:after { height: calc(100% - 40px); left: 20px; top: 20px; width: calc(100% - 40px); }
}
@media screen and (min-width: 960px) {
.e-mainsection.ee-pad .e-maincol.ee-vr { padding-right: 40px; }
.e-mainsection.ee-pad .e-maincol.ee-vr:before { background: #e6e6e6; content: ''; display: block; height: calc(100% - 20px); right: -1px; position: absolute; top: 20px; width: 2px; }
.e-mainsection.ee-pad .e-maincol.ee-vr + .e-maincol { padding-left: 40px; }
}
@media screen and (min-width: 1200px) {
.e-mainsection.ee-pad { padding: 20px 60px 40px 60px; }
.e-mainsection.ee-pad .e-maincol.ee-vr { padding-right: 60px; }
.e-mainsection.ee-pad .e-maincol.ee-vr + .e-maincol { padding-left: 60px; }
}

/* HR */
.e-mainhr-s { background: #e6e6e6; border: none; display: block; height: 1px; margin: 20px 0 0 0; width: 100%; }
.e-mainhr-l { background: linear-gradient(180deg, #f3f3f3 0, rgba(255,255,255,0) 20px); border: none; border-top: 1px solid #e6e6e6; display: block; height: 20px; margin: 40px -20px 0 -20px; width: calc(100% + 40px); }
.es-mainhr-l:not(.e-mainhr-s) { display: none; }
@media screen and (max-width: 959.999px) {
.e-mainsection.ee-pad-m .es-mainhr-l { background: linear-gradient(180deg, #f3f3f3 0, rgba(255,255,255,0) 20px); border: none; border-top: 1px solid #e6e6e6; display: block; height: 20px; margin: 40px -20px 0 -20px; width: calc(100% + 40px); }
}

/* MAIN TITLE */
.e-maintitle { background: var(--neutral-hex); color: #fff; display: block; overflow: hidden; padding: 10px 0 60px 0; position: relative; text-align: center; width: 100%; }
.e-maintitle:after { background: #333; bottom: 0; content: ''; display: block; height: 40px; left: 0; opacity: 0.5; position: absolute; width: 100%; }
.e-maintitle .ee-image { display: block; opacity: 0.5; overflow: hidden; padding: 50% 0 0 0;  position: absolute; right: 0; top: 50%; transform: translateY(-50%); width: 50%; }
.e-maintitle .ee-image:after { background: linear-gradient(270deg, rgba(255,255,255,0) 0, var(--neutral-hex) 100%); content: ''; display: block; height: 100%; position: absolute;  top: 0; transition: 0.3s; width: 50%; }
.e-maintitle .ee-image img { display: block; height: 100%; left: 50%; object-fit: cover; position: absolute; top: 50%; transform: translateX(-50%) translateY(-50%); width: 100%; }
.e-maintitle h1 { color: #fff; font-size: 18px; margin: 10px 0 0 0; text-align: left; }
.e-maintitle .e-buttonbullet { color: #fff; }
.e-maintitle .e-buttonbullet:before { filter: var(--filter-000-fff); }
.e-maintitle .e-countdown { bottom: 0; left: 50%; position: absolute; transform: translateX(-50%); z-index: 1; }
.e-maintitle .ee-button { border-radius: 0; bottom: 0; position: absolute; right: 0; z-index: 1; }
@media screen and (max-width: 639.999px) {
.e-maintitle .e-buttonbullet span { display: block; font-size: 0; height: 1px; margin: -1px 0 0 0; }
.e-maintitle .e-countdown { left: 0; right: auto; transform: none; }
}

/* TEXT */
.e-maintext { text-align: left; }
.e-maintext h1 { color: #333; font-size: 18px; font-weight: 900; margin: 20px 0 0 0; }
.e-maintext h2 { color: #333; font-size: 16px; font-weight: 700; margin: 20px 0 0 0; }
.e-maintext p { font-size: 14px; font-weight: 300; line-height: 20px; margin: 20px 0 0 0; }
.e-maintext p.ee-label strong { color: #333; display: inline-block; font-weight: 700; min-width: 60px; }
.e-maintext p.ee-label small { font-size: 10px; }
.e-maintext ul:not([class]) { font-size: 14px; font-weight: 300; line-height: 20px; list-style-type: none; margin: 20px 0 0 0; padding: 0; }
.e-maintext ul:not([class]) li { line-height: 20px; list-style-type: none; margin: 5px 0 0 0; padding: 0 0 0 20px; position: relative; }
.e-maintext ul:not([class]) li:before { background: #ccc; content: ''; display: block; height: 5px; left: 0; position: absolute; top: 9px; width: 5px; }
.e-maintext ol:not([class]) { counter-reset: main-ol; font-size: 14px; font-weight: 300; line-height: 20px; list-style-type: none; margin: 20px 0 0 0; }
.e-maintext ol:not([class]) li { counter-increment: main-ol; line-height: 20px; list-style-type: none; margin: 5px 0 0 0; padding: 0 0 0 20px; position: relative; }
.e-maintext ol:not([class]) li:before { content: counter(main-ol) '.'; color: #333; display: block; font-weight: 400; line-height: 20px; left: 0; position: absolute; top: 0; }
.e-maintext p a, .e-maintext ul:not([class]) li a, .e-maintext ol:not([class]) li a { color: #333; font-weight: 400; text-decoration: underline; }

.e-maintext.e-maintext-m h1, .e-maintext.e-maintext-m h2, .e-maintext.e-maintext-m p, .e-maintext.e-maintext-m ul:not([class]), .e-maintext.e-maintext-m ol:not([class]) { margin-top: 10px; }
.e-maintext h1.e-maintext-m, .e-maintext h2.e-maintext-m, .e-maintext p.e-maintext-m, .e-maintext ul:not([class]).e-maintext-m, .e-maintext ol:not([class]).e-maintext-m { margin-top: 10px; }

/* FEATURETEXT */
.e-featuretext { background: var(--success-hex); display: block; margin: 20px 0 0 0; overflow: hidden; padding: 0 20px 20px 20px; width: 100%; }
.e-featuretext:before { background: transparent url('icon/star.svg') no-repeat 50% 50%; background-size: cover; content: ''; display: block; filter: var(--filter-000-fff); height: 320px; opacity: 0.2; position: absolute; right: -160px; top: -160px; width: 320px; }
.e-featuretext h1 { color: #fff; }
.e-featuretext h2 { color: #fff; }
.e-featuretext p { color: #fff; font-weight: 400; }
.e-featuretext ul:not([class]) { display: block; font-size: 0;  list-style-type: none; margin: 0 -10px !important; padding: 0 !important; width: calc(100% + 20px); }
.e-featuretext ul:not([class]) li { color: #fff; display: inline-block; font-size: 16px; font-weight: 400; line-height: 20px; margin: 20px 0 0 0; list-style-type: none; padding: 0 10px 0 50px; position: relative; vertical-align: top; width: 50%; }
.e-featuretext ul:not([class]) li:before { background: transparent url('icon/star.svg') no-repeat 50% 50%; content: ''; display: block; filter: var(--filter-000-fff); height: 20px; left: 10px; position: absolute; top: 0; width: 20px; }
@media screen and (max-width: 719.999px) {
.e-featuretext { margin: 20px -20px 0 -20px; width: calc(100% + 40px); }
}
@media screen and (max-width: 639.999px) {
.e-featuretext ul:not([class]) li { width: 100%; }
}

/* CROPTEXT */
.e-croptext { display: block; text-align: left; width: 100%; }
.e-croptext > .ee-outer { display: block; overflow: hidden; position: relative; width: 100%; }
.e-croptext > .ee-outer:after { background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1)); border-bottom: 2px solid #333; bottom: 0; content: ''; display: block; height: 40px; left: 0; pointer-events: none; position: absolute; width: 100%; }
.e-croptext.ee-size-s > .ee-outer { height: 120px; }
.e-croptext.ee-size-m > .ee-outer { height: 240px; }
.e-croptext.ee-size-l > .ee-outer { height: 360px; }
.e-croptext.ee-active .ee-outer { height: auto !important; }
.e-croptext.ee-active > .ee-outer:after { display: none; }
.e-croptext > .ee-outer > .ee-inner { display: block; padding-bottom: 20px; width: 100%; }
.e-croptext > a { color: #333; cursor: pointer; display: block; font-size: 12px; font-weight: 700; margin: 5px 0 0 0; text-align: right; text-transform: uppercase; width: 100%; }
.e-croptext > a:before { content: attr(data-show); }
.e-croptext.ee-active > a:before { content: attr(data-hide); }
.e-croptext.ee-disabled > a { display: none; }

/* TITLE IMAGE */
.e-titleimage { display: block; margin: 20px 0 0 0; overflow: hidden; position: relative; width: 100%; }
.e-titleimage.ee-hr:before { background: #e6e6e6; content: ''; display: block; height: 1px; position: absolute; top: 0; width: calc(100% - 40px); }
.e-titleimage .ee-title { display: inline-block; vertical-align: middle; width: calc(100% - 80px); } 
.e-titleimage .ee-title h1 { margin-top: 0 !important; } 
.e-titleimage .ee-title h2 { margin-top: 0 !important; }
.e-titleimage .ee-title p { margin-top: 0 !important; }  
.e-titleimage .ee-image { background: var(--neutral-hex); border-radius: 50%; box-shadow: 0 0 0 10px #fff, -1px -1px 0 10px #e6e6e6; color: #fff; display: inline-block; font-weight: 700; position: relative; vertical-align: middle; text-align: center; } 
.e-titleimage .ee-image img { border-radius: 50%; display: block; height: 100%; left: 50%; object-fit: cover; position: absolute; top: 50%; transform: translateX(-50%) translateY(-50%); width: 100%; z-index: 1; }

.e-titleimage.ee-prefix.ee-hr:before { right: 0; }
.e-titleimage.ee-prefix .ee-title { padding: 0 0 0 40px; } 
.e-titleimage.ee-suffix.ee-hr:before { left: 0; }
.e-titleimage.ee-suffix .ee-title { padding: 0 40px 0 0; }

.e-titleimage.ee-size-s .ee-title { width: calc(100% - 40px); } 
.e-titleimage.ee-size-s .ee-image { font-size: 18px; height: 40px; line-height: 40px; width: 40px; }

.e-titleimage.ee-size-m:not(:last-child) { margin-bottom: -20px; }
.e-titleimage.ee-size-m .ee-title { width: calc(100% - 80px); } 
.e-titleimage.ee-size-m .ee-image { font-size: 24px; height: 80px; line-height: 80px; width: 80px; } 

/* COUNTDOWN */
.e-countdown { margin: 20px 0 0 0; }
.e-countdown ul { font-size: 0; list-style-type: none; margin: 0; padding: 0; text-align: center; }
.e-countdown li { background: #333; color: #fff; display: inline-block; height: 40px; margin: 0; text-transform: uppercase; width: 40px; }
.e-countdown li + li { margin-left: 1px; }
.e-countdown li strong { display: block; font-size: 18px; font-weight: 900; line-height: 22px; margin-top: 3px; }
.e-countdown li span { display: block; font-size: 10px; font-weight: 400; line-height: 10px; }

/* CAROUSEL */
.e-carousel { display: block; font-size: 0; margin: 0; overflow: hidden; width: 100%; }
.e-carousel img { border: 0; margin: 0; padding: 0; }

.e-carousel .ee-body { display: block; margin: 0; position: relative; width: 100%; }
.e-carousel[data-ratio="2-1"] .ee-body { padding: 50% 0 0 0; }
.e-carousel[data-ratio="16-9"] .ee-body { padding: 56.25% 0 0 0; }
.e-carousel[data-ratio="3-2"] .ee-body { padding: 66.67% 0 0 0; }

.e-carousel ul.ee-list { display: block; height: 100%; left: 0; list-style-type: none; margin: 0; padding: 0; position: absolute; top: 0; width: 100%; }
.e-carousel ul.ee-list > li { display: block; height: 100%; left: 0; list-style-type: none; margin: 0; overflow: hidden; padding: 0; position: absolute; top: 0; transition: 0.6s; width: 100%; }
.e-carousel[data-animate="fade"] ul.ee-list > li { opacity: 0; }
.e-carousel[data-animate="fade"] ul.ee-list > li.ee-active { opacity: 1; z-index: 1; }
.e-carousel[data-animate="slide"] ul.ee-list > li { opacity: 0.2; }
.e-carousel[data-animate="slide"] ul.ee-list > li.ee-active { opacity: 1; }

.e-carousel ul.ee-list .ee-image { display: block; height: 100%; left: 0; overflow: hidden; position: absolute; top: 0; width: 100%; }
.e-carousel ul.ee-list .ee-image { display: block; height: 100%; left: 0; overflow: hidden; position: absolute; top: 0; width: 100%; }
.e-carousel ul.ee-list .ee-image img { height: 100%; left: 50%; position: absolute; top: 50%; transform: translateX(-50%) translateY(-50%); width: 100%; }
.e-carousel[data-image="contain"] ul.ee-list .ee-image img { object-fit: contain; }
.e-carousel[data-image="cover"] ul.ee-list .ee-image img { height: 101%; object-fit: cover; width: 101%; }

.e-carousel a.ee-shift { background: rgba(51,51,51,0.8); display: block; height: 80px; position: absolute; top: 50%; transform: translateY(-50%); width: 40px; z-index: 2; }
.e-carousel a.ee-shiftprev { left: 0; }
.e-carousel a.ee-shiftnext { right: 0; }
.e-carousel a.ee-shift:before { border: solid #fff; border-width: 0 2px 2px 0; content: ''; display: inline-block; left: 50%; padding: 10px; position: absolute; top: 50%; transform-origin: 0 0;  }
.e-carousel a.ee-shiftprev:before { margin: 0 0 0 5px; transform: rotate(135deg) translateX(-50%) translateY(-50%); }
.e-carousel a.ee-shiftnext:before { margin: 0 0 0 -5px; transform: rotate(-45deg) translateX(-50%) translateY(-50%); }

.e-carousel .ee-foot { display: block; margin: 0; padding: 10px 0 0 0; position: relative; text-align: center; width: 100%; white-space: nowrap; }
.e-carousel[data-menu="icon"] .ee-foot { bottom: 0; left: 0; padding: 20px 0; position: absolute; }
.e-carousel ul.ee-menu { display: inline-block; list-style-type: none; margin: 0 auto; padding: 0 5px; text-align: center; z-index: 2; }
.e-carousel ul.ee-menu li { cursor: pointer; display: inline-block; font-size: 0; list-style-type: none; margin: 0 5px; position: relative; transition: 0.3s; }
.e-carousel[data-menu="icon"] ul.ee-menu li:before { border-radius: 50%; box-shadow: inset 0 0 0 2px #fff; content: ''; display: block; height: 20px; transition: 0.3s; width: 20px; }
.e-carousel[data-menu="icon"] ul.ee-menu li.ee-active:before { background: #fff; box-shadow: none; }
.e-carousel[data-menu="image"] ul.ee-menu li { height: 40px; overflow: hidden; position: relative; }
.e-carousel[data-menu="image"] ul.ee-menu li.ee-active:after { box-shadow: inset 0 0 0 2px #333; content: ''; display: block; height: 100%; left: 0; position: absolute; top: 0; width: 100%;  }
.e-carousel[data-menu="image"][data-image="contain"] ul.ee-menu li img { height: 100%; }
.e-carousel[data-menu="image"][data-image="cover"][data-ratio="2-1"] ul.ee-menu li { width: 80px; }
.e-carousel[data-menu="image"][data-image="cover"][data-ratio="3-2"] ul.ee-menu li { width: 60px; }
.e-carousel[data-image="cover"] ul.ee-menu li img { left: 50%; height: 100%; object-fit: cover; position: absolute; top: 50%; transform: translateX(-50%) translateY(-50%); width: 100%; }

.e-carousel[data-menu="0"] ul.ee-menu { display: none; }
.e-carousel[data-shift="0"] a.ee-shift { display: none; }
.e-carousel.ee-activefirst a.ee-shiftprev { display: none; }
.e-carousel.ee-activelast a.ee-shiftnext { display: none; }

.e-popupimage .e-carousel { height: 100%; left: 0; position: absolute; top: 0; width: 100%; }
.e-popupimage .e-carousel .ee-body { height: 100%; padding-top: 0 !important; }
.e-popupimage .e-carousel[data-menu="image"] .ee-body { height: calc(100% - 60px) !important; }
.e-popupimage .e-carousel[data-menu="image"] ul.ee-menu li.ee-active:after { box-shadow: inset 0 0 0 2px #fff; }

@media screen and (max-width: 719.999px) {
.e-carousel[data-menu-s="0"] ul.ee-menu { display: none; }
.e-carousel[data-shift-s="0"] a.ee-shift { display: none; }
.e-carousel[data-menu="icon"] .ee-foot { padding-bottom: 10px; }
.e-carousel[data-menu="icon"] ul.ee-menu li { margin: 0 2px; }
.e-carousel[data-menu="icon"] ul.ee-menu li:before { height: 10px; width: 10px; }
}
@media screen and (min-width: 720px){
.e-carousel a.ee-shift { height: 120px; width: 60px; }
.e-carousel a.ee-shift:before { border-radius: 2px; border-width: 0 4px 4px 0; padding: 15px; }
}
