/* VAR */
:root {
    --filter-000-fff: invert(100%) sepia(0%) saturate(2%) hue-rotate(174deg) brightness(104%) contrast(101%);
    --filter-000-333: invert(10%) sepia(14%) saturate(0%) hue-rotate(232deg) brightness(96%) contrast(74%);
    --filter-000-808080: invert(52%) sepia(10%) saturate(0%) hue-rotate(215deg) brightness(95%) contrast(76%);
    --filter-000-ccc: invert(88%) sepia(0%) saturate(86%) hue-rotate(190deg) brightness(92%) contrast(97%);

    --neutral-hex: #3380cc;
    --neutral-rgb: 51,128,204;
    --null-hex: #808080;
    --null-rgb: 128,128,128;
    --error-hex: #c33;
    --error-rgb: 204,51,51;
    --warning-hex: #ff8033;
    --warning-rgb: 255,128,51;
    --success-hex: #80cc33;
    --success-rgb: 128,204,51;
    }
    ::placeholder {  color: #333; opacity: 0.5; }
    input:focus, select:focus, textarea:focus { outline: none; }

    *:not(a)[data-switch-class]:not(.ee-active) { display: none !important; }
    a.ee-active[data-switch-class] { display: none !important; }

    /* ICON */
    .ei-alert:before { background-image: url('icon/alert.svg'); }
    .ei-bid:before { background-image: url('icon/bid.svg'); }
    .ei-calendar:before { background-image: url('icon/calendar.svg'); }
    .ei-check:before { background-image: url('icon/check.svg'); }
    .ei-cross:before { background-image: url('icon/cross.svg'); }
    .ei-cog:before { background-image: url('icon/cog.svg'); }
    .ei-delete:before { background-image: url('icon/delete.svg'); }
    .ei-down:before { background-image: url('icon/down.svg'); }
    .ei-download:before { background-image: url('icon/download.svg'); }
    .ei-edit:before { background-image: url('icon/edit.svg'); }
    .ei-email:before { background-image: url('icon/email.svg'); }
    .ei-enlarge:before { background-image: url('icon/enlarge.svg'); }
    .ei-enter:before { background-image: url('icon/enter.svg'); }
    .ei-expand:before { background-image: url('icon/expand.svg'); }
    .ei-file:before { background-image: url('icon/file.svg'); }
    .ei-grid:before { background-image: url('icon/grid.svg'); }
    .ei-hand:before { background-image: url('icon/hand.svg'); }
    .ei-image:before { background-image: url('icon/image.svg'); }
    .ei-increment:before { background-image: url('icon/increment.svg'); }
    .ei-left:before { background-image: url('icon/left.svg'); }
    .ei-list:before { background-image: url('icon/list.svg'); }
    .ei-location:before { background-image: url('icon/location.svg'); }
    .ei-lock:before { background-image: url('icon/lock.svg'); }
    .ei-minus:before { background-image: url('icon/minus.svg'); }
    .ei-money:before { background-image: url('icon/money.svg'); }
    .ei-move:before { background-image: url('icon/move.svg'); }
    .ei-movie:before { background-image: url('icon/movie.svg'); }
    .ei-news:before { background-image: url('icon/news.svg'); }
    .ei-person:before { background-image: url('icon/person.svg'); }
    .ei-plus:before { background-image: url('icon/plus.svg'); }
    .ei-reduce:before { background-image: url('icon/reduce.svg'); }
    .ei-right:before { background-image: url('icon/right.svg'); }
    .ei-search:before { background-image: url('icon/search.svg'); }
    .ei-sort:before { background-image: url('icon/sort.svg'); }
    .ei-star:before { background-image: url('icon/star.svg'); }
    .ei-street:before { background-image: url('icon/street.svg'); }
    .ei-tel:before { background-image: url('icon/tel.svg'); }
    .ei-time:before { background-image: url('icon/time.svg'); }
    .ei-tick:before { background-image: url('icon/tick.svg'); }
    .ei-view:before { background-image: url('icon/view.svg'); }
    .ei-up:before { background-image: url('icon/up.svg'); }
    .ei-refresh:before { background-image: url('icon/refresh.svg'); }
    .ei-visible-not:before { background-image: url('icon/visible-not.svg'); }
    .ei-visible:before { background-image: url('icon/visible.svg'); }


    /* FIELD TEXT */
    .e-fieldtext { position: relative; width: 100%; }
    .e-fieldtext input, .e-fieldtext textarea, .e-fieldtext .ee-fake { background: #f3f3f3; border: none; border: 1px solid #ccc; border-radius: 3px; color: #333; display: block; font-size: 14px; font-weight: 300; height: 40px; line-height: 20px; margin: 0; padding: 10px 5px; width: 100%; }
    .e-fieldtext.ee-height-s textarea { height: 115px; }
    .e-fieldtext.ee-height-m textarea { height: 290px; }
    .e-fieldtext.ee-height-l textarea { height: 365px; }

    .e-fieldtext .ee-prefix, .e-fieldtext .ee-suffix { color: #333; font-size: 14px; font-weight: 700; height: 20px; margin: 0; padding: 0; pointer-events: none; position: absolute;  text-align: center; top: 10px; width: 20px; z-index: 1; }
    .e-fieldtext .ee-prefix { left: 10px; }
    .e-fieldtext .ee-suffix { right: 10px; }
    .e-fieldtext .ee-prefix:before, .e-fieldtext .ee-suffix:before { background-color: transparent; background-size: 100%; background-position: 50% 50%; background-repeat: no-repeat; content: ''; display: block; filter: var(--filter-000-333); height: 20px; left: 0; position: absolute; top: 0; width: 20px; }
    .e-fieldtext .ee-prefix + input { padding-left: 40px; }
    .e-fieldtext .ee-suffix + input { padding-right: 40px; }

    /* FIELD SELECT */
    .e-fieldselect { position: relative; width: 100%; }
    .e-fieldselect select { appearance: none; background: #f3f3f3; border: none; border: 1px solid #ccc; border-radius: 3px; color: #333; display: block; font-size: 14px; font-size: 14px; font-weight: 300; height: 40px; line-height: 20px; margin: 0; padding: 10px 30px 10px 5px; width: 100%; }
    .e-fieldselect:after { border: solid #333; border-radius: 1px; border-width: 0 2px 2px 0; content: ''; display: block; padding: 3px; pointer-events: none; position: absolute; right: 15px; top: 15px; transform: rotate(45deg); }

    /* FIELD CHECK */
    .e-fieldcheck { margin: 10px 0 0 0; position: relative; width: 100%; }
    .e-fieldcheck input { display: none; }
    .e-fieldcheck > label { color: #333; cursor: pointer; display: block; font-size: 14px; font-weight: 300; line-height: 30px; padding: 0 0 0 40px; position: relative; }
    .e-fieldcheck > input[type="checkbox"] + label:before { background: #f3f3f3; border-radius: 3px; box-shadow: inset 0 0 0 1px #ccc, inset 0 0 0 3px #fff; content: ''; display: block; height: 30px; left: 0; position: absolute; top: 0; width: 30px; }
    .e-fieldcheck > input[type="checkbox"]:checked  + label:before { box-shadow: inset 0 0 0 1px #333, inset 0 0 0 3px #fff; }
    .e-fieldcheck > input[type="checkbox"]:checked  + label:after { background: transparent url('image/fieldcheckbox.svg') no-repeat 50% 50%; background-size: 100%; content: ''; display: block; filter: var(--filter-000-333); height: 20px; left: 5px; position: absolute; top: 5px; width: 20px; }
    .e-fieldcheck > input[type="radio"] + label:before { background: #f3f3f3; border-radius: 20px; box-shadow: inset 0 0 0 1px #ccc, inset 0 0 0 3px #fff; content: ''; display: block; height: 30px; left: 0; position: absolute; top: 0; width: 30px; }
    .e-fieldcheck > input[type="radio"]:checked  + label:before { background: #000; }

    /* FIELD */
    .e-field { display: block; margin: 10px 0 0 0; position: relative; text-align: left; width: 100%; }
    .e-field > label { color: #333; display: block; font-size: 14px; font-weight: 300; line-height: 20px; margin: 0 0 5px 0; }

    /* FIELD BUTTON */
    .e-fieldbutton { display: flex; font-size: 0; }
    .e-fieldbutton .e-buttontext { display: inline-block; flex-shrink: 0; }
    .e-fieldbutton .e-buttontext:first-child { border-bottom-right-radius: 0; border-top-right-radius: 0; }
    .e-fieldbutton .e-buttontext:last-child { border-bottom-left-radius: 0; border-top-left-radius: 0; }
    .e-fieldbutton .e-fieldtext { display: inline-block; }
    .e-fieldbutton .e-fieldtext:not(:last-child) input, .e-fieldbutton .e-fieldtext:not(:last-child) .ee-fake { border-right: none; border-bottom-right-radius: 0; border-top-right-radius: 0; }
    .e-fieldbutton .e-fieldtext:not(:first-child) input, .e-fieldbutton .e-fieldtext:not(:first-child) .ee-fake { border-left: none; border-bottom-left-radius: 0; border-top-left-radius: 0; }

    /* ERROR    */
    p.e-fielderror { box-sizing: border-box; color: var(--error-hex); font-size: 12px; line-height: 16px !important; margin: 2px 0 0 0 !important; padding: 0 0 0 20px; position: relative; text-align: left; }
    p.e-fielderror:before { border-radius: 50%; background: var(--error-hex) url('image/fielderror.svg') no-repeat 50% 50%; content: ''; height: 16px; left: 0; position: absolute; text-align: center; top: 0; width: 16px; }
    .ee-error input, .ee-error textarea { background: rgba(var(--error-rgb),0.1);  border: 1px solid rgba(var(--error-rgb),0.2); }
    .ee-error select { background: rgba(var(--error-rgb),0.1); border: 1px solid rgba(var(--error-rgb),0.2); }

    /* LIST BULLET */
    ul.e-listbullet { display: block; list-style-type: none; margin: 0; padding: 0; text-align: left; }
    ul.e-listbullet li { display: block; margin: 20px 0 0 0; list-style-type: none; padding: 0; }
    @media screen and (min-width: 720px) {
    ul.e-listbullet.ee-col { margin: 0 -20px; width: calc(100% + 40px) }
    ul.e-listbullet.ee-col li { display: inline-block; padding: 0 20px; vertical-align: top; width: 50%; }
    ul.e-listbullet.ee-col li.ee-width-33 { width: 33.33%; }
    ul.e-listbullet.ee-col li.ee-width-66 { width: 66.67%; }
    }

    /* LIST CHECK */
    ul.e-listcheck { display: block; list-style-type: none; margin: 0 -20px 0 0; padding: 0; text-align: left; width: calc(100% + 20px); }
    ul.e-listcheck li { display: inline-block; list-style-type: none; margin: 0 20px 0 0; padding: 0; vertical-align: middle; }
    ul.e-listcheck .e-fieldcheck { display: inline-block; width: auto; }

    /* BUTTON TEXT */
    .e-buttontext { border-radius: 3px; box-sizing: border-box;  display: inline-block; font-weight: 700; line-height: 20px; margin: 0; position: relative; text-align: center; text-decoration: none; text-transform: uppercase; transition: 0.3s; vertical-align: middle; }
    .e-buttontext.ee-prefix:before, .e-buttontext.ee-suffix:before, .e-buttontext.ee-empty:before { background-color: transparent; background-size: 100%; background-position: 50% 50%; background-repeat: no-repeat; content: ''; display: block; height: 20px; position: absolute; width: 20px; }

    .e-buttontext.ee-size-m { font-size: 14px; padding: 10px 20px; }
    .e-buttontext.ee-size-m.ee-empty { font-size: 0; height: 40px; padding: 0 !important; width: 40px; }
    .e-buttontext.ee-size-m.ee-square { height: 40px; padding: 0 !important; width: 40px; }
    .e-buttontext.ee-size-m.ee-width-s { padding: 10px 5px; width: 80px; }
    .e-buttontext.ee-size-m.ee-width-m { padding: 10px 5px; width: 160px; }
    .e-buttontext.ee-size-m.ee-width-l { padding: 10px 5px; width: 240px; }
    .e-buttontext.ee-size-m.ee-prefix { padding: 10px 20px 10px 40px; }
    .e-buttontext.ee-size-m.ee-prefix:before, .e-buttontext.ee-size-m.ee-empty:before { left: 10px; top: 10px; }
    .e-buttontext.ee-size-m.ee-suffix { padding: 10px 40px 10px 20px; }
    .e-buttontext.ee-size-m.ee-suffix:before { right: 10px; top: 10px; }

    .e-buttontext.ee-size-s { font-size: 12px; padding: 5px 10px; }
    .e-buttontext.ee-size-s.ee-empty { font-size: 0; height: 30px; padding: 0 !important; width: 30px; }
    .e-buttontext.ee-size-s.ee-square { height: 30px; padding: 0 !important; width: 30px; }
    .e-buttontext.ee-size-s.ee-width-xs { padding: 5px; width: 40px; }
    .e-buttontext.ee-size-s.ee-width-s { padding: 5px; width: 60px; }
    .e-buttontext.ee-size-s.ee-width-m { padding: 5px; width: 120px; }
    .e-buttontext.ee-size-s.ee-width-l { padding: 5px; width: 180px; }
    .e-buttontext.ee-size-s.ee-prefix { padding: 5px 10px 5px 30px; }
    .e-buttontext.ee-size-s.ee-prefix:before, .e-buttontext.ee-size-s.ee-empty:before { left: 5px; top: 5px; }
    .e-buttontext.ee-size-s.ee-suffix { padding: 5px 30px 5px 10px; }
    .e-buttontext.ee-size-s.ee-suffix:before { right: 5px; top: 5px; }

    .e-buttontext.ee-vis-s { box-shadow: inset 0 0 0 1px #e6e6e6; color: #333; font-weight: 400; }
    .e-buttontext.ee-vis-s:hover { box-shadow: inset 0 0 0 1px #ccc; }
    .e-buttontext.ee-vis-s.ee-prefix:before, .e-buttontext.ee-vis-s.ee-suffix:before, .e-buttontext.ee-vis-s.ee-empty:before { filter: var(--filter-000-333); }
    .e-buttontext.ee-vis-m { box-shadow: inset 0 0 0 2px #333; color: #333; }
    .e-buttontext.ee-vis-m:hover { background: #333; color: #fff; }
    .e-buttontext.ee-vis-m.ee-prefix:before, .e-buttontext.ee-vis-m.ee-suffix:before, .e-buttontext.ee-vis-m.ee-empty:before { filter: var(--filter-000-333); }
    .e-buttontext.ee-vis-l { background: #333; color: #fff; }
    .e-buttontext.ee-vis-l:hover { background: #000; }
    .e-buttontext.ee-vis-l.ee-prefix:before, .e-buttontext.ee-vis-l.ee-suffix:before, .e-buttontext.ee-vis-l.ee-empty:before { filter: var(--filter-000-fff); }
    .e-buttontext.ee-vis-l.ee-warning { background-color: var(--warning-hex); }
    .e-buttontext.ee-vis-l.ee-error { background-color: var(--error-hex); }
    .e-buttontext.ee-vis-l.ee-success { background-color: var(--success-hex); }

    /* BUTTON BULLET */
    .e-buttonbullet { box-sizing: border-box; display: inline-block; text-decoration-color: #808080; position: relative; transition: 0.3s; vertical-align: middle; }
    .e-buttonbullet:before { background-color: transparent; background-size: 100%; background-position: 50% 50%; background-repeat: no-repeat; content: ''; display: block; filter: var(--filter-000-333); left: 0; position: absolute; top: 0; z-index: 1; }
    .e-buttonbullet { color: #333; font-weight: 400; }
    .e-buttonbullet.ee-empty { font-size: 0 !important; }
    span.e-buttonbullet { font-weight: 300; }
    .e-buttonbullet:after { background: var(--neutral-hex); border-radius: 50%; color: #fff; display: block; font-weight: bold; left: 0; line-height: 30px; position: absolute; text-align: center; top: 0; width: 30px; }
    .e-buttonbullet.ee-null:after { background-color: var(--null-hex); }
    .e-buttonbullet.ee-warning:after { background-color: var(--warning-hex); }
    .e-buttonbullet.ee-error:after { background-color: var(--error-hex); }
    .e-buttonbullet.ee-success:after { background-color: var(--success-hex); }

    .e-buttonbullet.ee-size-s { font-size: 14px; line-height: 20px; padding: 0 0 0 40px; }
    .e-buttonbullet.ee-size-s.ee-empty { height: 20px; padding: 0; width: 20px; }
    .e-buttonbullet.ee-size-s:before { height: 20px; width: 20px; }

    .e-buttonbullet.ee-size-m { font-size: 14px; line-height: 20px; min-height: 30px; padding: 5px 0 0 50px; }
    .e-buttonbullet.ee-size-m.ee-empty { height: 30px; padding: 0; width: 30px; }
    .e-buttonbullet.ee-size-m:before { filter: var(--filter-000-fff); height: 20px; left: 5px; top: 5px; width: 20px; }
    .e-buttonbullet.ee-size-m:after { content: ''; font-size: 14px; height: 30px; line-height: 30px; width: 30px; }
    .e-buttonbullet.ee-size-m[data-after]:after { content: attr(data-after); }
    .e-buttonbullet.ee-size-m strong { font-size: 18px; font-weight: 700; }

    .e-buttonbullet.ee-size-l { font-size: 14px; line-height: 20px; min-height: 40px; padding: 10px 0 0 60px; }
    .e-buttonbullet.ee-size-l.ee-empty { height: 40px; padding: 0; width: 40px; }
    .e-buttonbullet.ee-size-l:before { filter: var(--filter-000-fff); height: 20px; left: 10px; top: 10px; width: 20px; }
    .e-buttonbullet.ee-size-l:after { content: ''; font-size: 18px; height: 40px; line-height: 40px; width: 40px; }
    .e-buttonbullet.ee-size-l[data-after]:after { content: attr(data-after); }
    .e-buttonbullet.ee-size-l strong { font-size: 18px; font-weight: 700;  }

    /* BUTTON PERSON */
    .e-buttonperson { box-sizing: border-box; align-items: center; display: flex; flex-flow: row; gap: 10px; justify-content: flex-start; overflow: hidden; position: relative; transition: 0.3s; vertical-align: middle; text-decoration: none; }
    .e-buttonperson .ee-image { background: var(--neutral-hex); border-radius: 50%; color: #fff; content: ''; display: inline-block; flex-shrink: 0; font-size: 14px; font-weight: 700; height: 40px; line-height: 40px; overflow: hidden; position: relative; vertical-align: middle; text-align: center; width: 40px; }
    .e-buttonperson .ee-image img { display: block; height: 100%; left: 50%; object-fit: cover; position: absolute; top: 50%; transform: translateX(-50%) translateY(-50%); width: 100%; }
    .e-buttonperson .ee-title { color: #333; display: inline-block; font-size: 14px; flex-grow: 3; }
    .e-buttonperson .ee-title strong { display: block; text-decoration: underline; text-decoration-color: #808080; }

    /* BUTTONROW */
    .e-buttonrow { display: inline-block; font-size: 0; position: relative; text-align: center; white-space: nowrap; }
    .e-buttonrow a { background: #fff; box-shadow: inset 0 0 0 1px #e6e6e6; box-sizing: border-box; color: #333; display: inline-block; font-size: 14px; font-weight: 700; display: inline-block; line-height: 40px; margin: 0; min-width: 100px; padding: 0 5px; position: relative; vertical-align: middle; text-transform: uppercase; }
    .e-buttonrow a + a { margin-left: -1px; }
    .e-buttonrow a:first-child { border-bottom-left-radius: 3px; border-top-left-radius: 3px; }
    .e-buttonrow a:last-child { border-bottom-right-radius: 3px; border-top-right-radius: 3px; }
    .e-buttonrow a:hover { box-shadow: inset 0 0 0 1px #ccc; z-index: 1; }
    .e-buttonrow a.ee-active { box-shadow: inset 0 0 0 2px #333; color: #fff; z-index: 2; }
    .e-buttonrow a:empty { height: 40px; min-width: 40px; width: 40px; }
    .e-buttonrow a:empty:before { background-color: transparent; background-size: 100%; background-position: 50% 50%; background-repeat: no-repeat; content: ''; display: block; filter: var(--filter-000-333); height: 20px; left: 10px; position: absolute; top: 10px; width: 20px; }
    .e-buttonrow[data-before]:before { background: #fff; color: #808080; content: attr(data-before); display: block; font-size: 10px; font-weight: 400; left: 50%; line-height: 14px; padding: 0 5px; pointer-events: none; position: absolute; text-transform: uppercase; top: -6px; transform: translateX(-50%); z-index: 2; }
    .e-buttonrow[data-after]:after { background: #fff; bottom: -6px; color: #808080; content: attr(data-after); display: block; font-size: 10px; font-weight: 400; left: 50%; line-height: 14px; padding: 0 5px; pointer-events: none; position: absolute; text-transform: uppercase; transform: translateX(-50%); z-index: 2; }

    /* FORM SUBMIT */
    .e-formsubmit { font-size: 0; margin: 0 -10px; text-align: right; width: calc (100% + 20px); }
    .e-formsubmit .e-buttontext { margin: 20px 10px 0 10px; }

    /* FORM ROW & COL */
    .e-formrow { align-items: stretch; display: flex; flex-flow: row wrap; justify-content: flex-start; width: 100%; }
    .e-formcol { display: inline-block; vertical-align: top; width: 100%; }

    .e-formrow.ee-pad-l { margin: 0 -20px; width: calc(100% + 40px); }
    .e-formrow.ee-pad-l .e-formcol { padding: 0 20px; }
    .e-formrow.ee-pad-l.ee-box { margin: 10px -30px 0 -30px; width: calc(100% + 60px); }
    .e-formrow.ee-pad-l.ee-box + .e-formrow.ee-pad-l.ee-box { margin-top: 0; }
    .e-formrow.ee-pad-l.ee-box > .e-formcol { padding: 10px 20px 30px 20px; }
    .e-formrow.ee-pad-l.ee-box >
    .e-formcol:before { border-radius: 3px; box-shadow: 0 0 0 1px #e6e6e6, 0 0 20px #e6e6e6; content: ''; display: block; height: calc(100% - 20px); left: 10px; position: absolute; top: 10px; width: calc(100% - 20px); z-index: -1; }

    .e-formrow.ee-pad-m { margin: 0 -10px; width: calc(100% + 20px); }
    .e-formrow.ee-pad-m .e-formcol { padding: 0 10px; }
    .e-formrow.ee-pad-s { margin: 0 -5px; width: calc(100% + 10px); }
    .e-formrow.ee-pad-s .e-formcol { padding: 0 5px; }
    .e-formcol.ee-width-25 { width: 25%; }
    .e-formcol.ee-width-33 { width: 33.3%; }
    .e-formcol.ee-width-50 { width: 50%; }
    .e-formcol.ee-width-66 { width: 66.7%; }
    .e-formcol.ee-width-75 { width: 75%; }
    @media screen and (min-width: 480px) {
    .e-formcol.es-width-25 { width: 25%; }
    .e-formcol.es-width-33 { width: 33.3%; }
    .e-formcol.es-width-50 { width: 50%; }
    .e-formcol.es-width-66 { width: 66.7%; }
    .e-formcol.es-width-75 { width: 75%; }
    }
    @media screen and (min-width: 720px) {
    .e-formcol.em-width-25 { width: 25%; }
    .e-formcol.em-width-33 { width: 33.3%; }
    .e-formcol.em-width-50 { width: 50%; }
    .e-formcol.em-width-66 { width: 66.7%; }
    .e-formcol.em-width-75 { width: 75%; }
    }
    @media screen and (min-width: 960px) {
    .e-formcol.el-width-25 { width: 25%; }
    .e-formcol.el-width-33 { width: 33.3%; }
    .e-formcol.el-width-50 { width: 50%; }
    .e-formcol.el-width-66 { width: 66.7%; }
    .e-formcol.el-width-75 { width: 75%; }
    .e-formrow.ee-pad-l.ee-box > .e-formcol { padding: 20px 30px 30px 30px; }
    }

    /* STATUS */
    .e-formstatus { color: var(--neutral-hex); display: inline-block; font-size: 12px; font-weight: 700; margin: 0; position: relative; text-align: left; text-transform: uppercase; vertical-align: middle; }
    .e-formstatus.ee-bullet { height: 30px; line-height: 30px; padding: 0 0 0 35px; }
    .e-formstatus.ee-button { font-size: 0; height: 30px; width: 30px; }
    .e-formstatus.ee-bullet:before, .e-formstatus.ee-button:before { border-radius: 50%; background: var(--neutral-hex) url('image/status.svg') no-repeat 50% 50%; background-size: 20px; content: ''; display: block; height: 30px; left: 0; position: absolute; text-align: center; top: 0; width: 30px; }
    .e-formstatus.ee-null, .e-formstatus.ee-unset { color: var(--null-hex); }
    .e-formstatus.ee-bullet.ee-null:before, .e-formstatus.ee-button.ee-null:before, .e-formstatus.ee-bullet.ee-unset:before, .e-formstatus.ee-button.ee-unset:before { background-color: var(--null-hex); background-image: url('image/statusno.svg'); }
    .e-formstatus.ee-warning, .e-formstatus.ee-undefined { color: var(--warning-hex); }
    .e-formstatus.ee-bullet.ee-warning:before, .e-formstatus.ee-button.ee-warning:before, .e-formstatus.ee-bullet.ee-undefined:before, .e-formstatus.ee-button.ee-undefined:before { background-color: var(--warning-hex); background-image: url('image/statusundefined.svg') }
    .e-formstatus.ee-error, .e-formstatus.ee-no { color: var(--error-hex); }
    .e-formstatus.ee-bullet.ee-error:before, .e-formstatus.ee-button.ee-error:before, .e-formstatus.ee-bullet.ee-no:before, .e-formstatus.ee-button.ee-no:before { background-color: var(--error-hex); background-image: url('image/statusno.svg'); }
    .e-formstatus.ee-success, .e-formstatus.ee-yes { color: var(--success-hex); }
    .e-formstatus.ee-bullet.ee-success:before, .e-formstatus.ee-button.ee-success:before, .e-formstatus.ee-bullet.ee-yes:before, .e-formstatus.ee-button.ee-yes:before { background-color: var(--success-hex); background-image: url('image/statusyes.svg'); }

    /* STAT */
    ul.e-formstat { display: flex; flex-flow: row wrap; justify-content: center; list-style-type: none; margin: 0; padding: 0; }
    ul.e-formstat li { display: inline-block; list-style-type: none; margin: 20px 0 0 0; padding: 0; text-align: center; width: 100px; }
    ul.e-formstat .ee-bullet { background-color: var(--neutral-hex); border-radius: 50%; box-sizing: border-box; color: #fff; display: block; font-size: 18px; font-weight: 700; height: 80px; line-height: 40px; list-style-type: none; margin: 0 auto; padding: 40px 0 0 0; width: 80px; }
    ul.e-formstat .ee-bullet.ee-null, ul.e-formstat .ee-bullet.ee-unset { background-color: var(--null-hex); }
    ul.e-formstat .ee-bullet.ee-warning, ul.e-formstat .ee-bullet.ee-undefined { background-color: var(--warning-hex); }
    ul.e-formstat .ee-bullet.ee-error, ul.e-formstat .ee-bullet.ee-no { background-color: var(--error-hex); }
    ul.e-formstat .ee-bullet.ee-success, ul.e-formstat .ee-bullet.ee-yes { background-color: var(--success-hex); }
    ul.e-formstat .ee-bullet:before { background-position: 50% 50%; background-repeat: no-repeat; background-size: 100%; content: ''; display: block; filter: var(--filter-000-fff); height: 40px; left: 50%; margin: 0 0 0 -20px; opacity: 0.2; position: absolute; text-align: center; top: 10px; width: 40px; }
    ul.e-formstat .ee-text { display: block; font-size: 12px; margin: 5px 0 0 0; text-transform: uppercase; }
    @media screen and (min-width: 640px) {
    ul.e-formstat li { width: 120px; }
    }

    /* BUTTON MENU */
    .e-buttonmenu { display: inline-block; position: relative; vertical-align: middle; }
    .e-buttonmenu > a { display: inline-block; position: relative; }
    .e-buttonmenu > ul { background: #333; border-radius: 3px; box-shadow: 0 0 0 2px #fff; display: inline-block; display: none; list-style-type: none; margin: 0; padding: 5px 10px; position: absolute; top: 30px; transition: 0.3s; }
    .e-buttonmenu.ee-left > ul { border-top-left-radius: 0; left: 0; }
    .e-buttonmenu.ee-right > ul { border-top-right-radius: 0;right: 0; }
    .e-buttonmenu > ul li { display: block; margin: 0; padding: 0; }
    .e-buttonmenu > ul li + li { border-top: 1px solid rgba(255,255,255,0.2); }
    .e-buttonmenu > ul li a { box-sizing: border-box; color: #fff; display: block; font-size: 12px; font-weight: 700; line-height: 20px; margin: 0; padding: 5px 0; text-align: left; white-space: nowrap; }
    .e-buttonmenu > a.ee-active { z-index: 2; }
    .e-buttonmenu > a.e-formstatus.ee-active:before { border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
    .e-buttonmenu > a.e-buttontext.ee-active { background-color: #333; border-bottom-left-radius: 0; border-bottom-right-radius: 0; box-shadow: none; }
    .e-buttonmenu > a.e-buttontext.ee-active:before { filter: var(--filter-000-fff); }
    .e-buttonmenu > a.e-buttonbullet.ee-active:after { border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
    .e-buttonmenu > a.ee-active + ul { display: inline-block; z-index: 1; }
    .e-buttonmenu > ul li > span { box-sizing: border-box; color: #fff; display: block; font-size: 12px; font-weight: 300; margin: 0; padding: 5px 0; text-align: left; white-space: nowrap; }
    .e-buttonmenu > ul li > span small { display: inline-block; font-size: 12px; vertical-align: middle; }
    .e-buttonmenu > a.e-buttontext.ee-size-m + ul { top: 40px; }

    /* TITLE */
    .e-formtitle { align-items: center; display: flex; flex-flow: row; gap: 10px; justify-content: flex-start; width: 100%; }
    .e-formtitle h1 { display: inline-block; font-size: 18px; margin: 20px 0 0 0 !important; flex-grow: 3; }
    .e-formtitle .e-buttontext { display: inline-block; flex-shrink: 0; margin: 20px 0 0 0 !important; }

    /* BULLET */
    ul.e-formbullet { border-bottom: 1px solid #e6e6e6; list-style-type: none; margin: 10px 0 0 0; padding: 0; width: 100%; }
    ul.e-formbullet li { align-items: center; border-top: 1px solid #e6e6e6; display: flex; flex-flow: row; gap: 10px; justify-content: flex-start; list-style-type: none; margin: 0; padding: 10px 0; width: 100%; }
    ul.e-formbullet li .e-buttontext { display: inline-block; flex-shrink: 0; margin: 0 !important; }
    ul.e-formbullet li > .ee-image { display: inline-block; flex-grow: 3; height: 50px; margin: -5px 0 !important; }
    ul.e-formbullet li > .ee-image img { border-radius: 3px; display: block; height: 100%; margin: 0; padding: 0; }
    ul.e-formbullet li .e-buttonbullet { display: inline-block; margin: 0 !important; flex-grow: 3; }
    ul.e-formbullet li .e-buttonperson { margin: 0 !important; flex-grow: 3; }
    ul.e-formbullet.ea-sortable { background: #e6e6e6; box-shadow: inset 0 0 0 1px #ccc; overflow: hidden; padding: 8px 10px 10px 10px; }
    ul.e-formbullet.ea-sortable li { background: #fff; box-shadow: inset 0 0 0 1px #ccc; cursor: pointer; margin-top: 2px; padding: 10px; }
    ul.e-formbullet.ea-sortable li > .ee-image { margin-left: -5px !important; }

    div.e-formbullet { align-items: center; list-style-type: none; margin: 10px 0 0 0; position: relative; display: flex; flex-flow: row; gap: 10px; justify-content: flex-start; width: 100%; }
    .e-field div.e-formbullet { margin-top: 5px; }
    div.e-formbullet .e-buttontext { display: inline-block; flex-shrink: 0; margin: 0 !important; }
    div.e-formbullet .e-buttonbullet { display: inline-block; margin: 0 !important; flex-grow: 3; }

    /* IMAGE */
    ul.e-formimage { align-items: center; border-top: 1px solid #e6e6e6; display: flex; flex-flow: row wrap; gap: 10px; justify-content: flex-start; list-style-type: none; margin: 10px 0 0 0; padding: 10px 0 0 0; width: 100%; }
    ul.e-formimage li { display: inline-block; flex-shrink: 0; list-style-type: none; margin: 0; padding: 0; position: relative; }
    ul.e-formimage li .e-buttontext { border-bottom-right-radius: 0; border-top-left-radius: 0; box-shadow: 0 0 0 1px rgba(255,255,255,0.5); display: inline-block; flex-shrink: 0; margin: 0 !important; position: absolute; right: 0; top: 0; }
    ul.e-formimage li .ee-image { display: block; height: 80px; }
    ul.e-formimage li .ee-image img { border-radius: 3px; display: block; height: 80px; }
    ul.e-formimage.ea-sortable { background: #e6e6e6; box-shadow: inset 0 0 0 1px #ccc; counter-reset: easortable; margin-top: 10px; overflow: hidden; padding: 10px; }
    ul.e-formimage.ea-sortable li { counter-increment: easortable; cursor: pointer; }
    ul.e-formimage.ea-sortable li .e-buttontext:before { content: counter(easortable); line-height: 30px; }

    div.e-formimage { display: inline-block; margin: 10px 0 0 0; padding: 0 30px 0 0; position: relative; transition: 0.3s; }
    div.e-formimage .ee-image { display: inline-block; height: 65px; position: relative; vertical-align: top; transition: 0.3s; }
    div.e-formimage .ee-image img { border-radius: 3px; border-top-right-radius: 0; display: block; height: 100%; transition: 0.3s; }
    div.e-formimage .ea-enlarge { background: #fff; bottom: 0; cursor: pointer; display: block; font-size: 0; height: 30px; position: absolute; right: 0; transition: 0.3s; width: 30px;  }
    div.e-formimage .ea-enlarge:before { background: transparent url('icon/enlarge.svg') no-repeat 50% 50%; background-size: 20px; content: ''; display: block; filter: var(--filter-000-333); height: 20px; left: calc(50% - 10px); position: absolute; top: calc(50% - 10px); width: 20px; }
    div.e-formimage .e-buttontext { border-bottom-left-radius: 0; border-top-left-radius: 0; display: inline-block; margin: 0 !important; position: absolute; right: 0; top: 0; vertical-align: top; }

    div.e-formimage.ee-active { padding: 0; width: 100%; }
    div.e-formimage.ee-active .ee-image { height: auto; width: 100%; }
    div.e-formimage.ee-active .ee-image img { border-top-right-radius: 3px; height: auto; width: 100%; }
    div.e-formimage.ee-active .ea-enlarge { border-top-left-radius: 3px; }
    div.e-formimage.ee-active .ea-enlarge:before { background-image: url('icon/reduce.svg'); }
    div.e-formimage.ee-active .e-buttontext { border-radius: 3px; border-bottom-right-radius: 0; border-top-left-radius: 0; box-shadow: 0 0 0 1px rgba(255,255,255,0.5); }


    /* SUMMARY */
    dl.e-formsummary { display: block; margin: 0; outline: none; padding: 0; text-align: left; width: 100%; }
    dl.e-formsummary dt { box-sizing: border-box; border-top: 1px solid #e6e6e6; display: inline-block; font-size: 12px; font-weight: 700; line-height: 20px; margin: 10px 0 0 0; padding: 10px 5px 0 0; text-transform: uppercase; vertical-align: top; width: 33.33%; }
    dl.e-formsummary dt.ee-width-0 { width: auto; }
    dl.e-formsummary dd { box-sizing: border-box; border-top: 1px solid #e6e6e6; display: inline-block; font-size: 14px; font-weight: 300; line-height: 20px; margin: 10px 0 0 0; padding: 10px 0 0 5px; vertical-align: top; width: 66.67%; }
    dl.e-formsummary hr.ee-hr { background: #e6e6e6; border: none; display: block; height: 1px; margin: 10px 0; width: 100%; }
    dl.e-formsummary a { ciursor: pointer; text-decoration: underline; text-decoration-color: #808080; }
    dl.e-formsummary dt.ee-hr { border-top: 2px solid #333; }
    dl.e-formsummary dt.ee-hr + dd { border-top: 2px solid #333; }
    dl.e-formsummary dt.ee-grow { width: 66.67%; }
    dl.e-formsummary dt.ee-grow + dd { width: 33.33%; }
    dl.e-formsummary + hr.e-mainhr-s { margin-top: 10px; }
    dl.e-formsummary img { display: block; margin: 0; max-height: 60px; max-width: 100%; }

    h2.e-formhr { border-top: 2px solid #333; font-size: 12px !important; margin: 20px 0 0 0 !important; padding: 10px 0 0 0; text-transform: uppercase; }
    p.e-formhr { border-top: 2px solid #333;  margin: 20px 0 0 0 !important; padding: 10px 0 0 0; }

    /* NEWSPAPER */
    .e-formnewspaper { column-gap: 20px; display: block; margin: 20px 0 0 0; width: 100%; }
    .e-formnewspaper :first-child { margin-top: 0 !important; }
    @media screen and (min-width: 480px) {
    .e-formnewspaper.ee-col-2 { column-count: 1; }
    .e-formnewspaper.ee-col-3 { column-count: 1; }
    .e-formnewspaper.ee-col-4 { column-count: 2; }
    }
    @media screen and (min-width: 600px) {
    .e-formnewspaper.ee-col-2 { column-count: 2; }
    .e-formnewspaper.ee-col-3 { column-count: 2; }
    .e-formnewspaper.ee-col-4 { column-count: 3; }
    }
    @media screen and (min-width: 720px) {
    .e-formnewspaper.ee-col-2 { column-count: 2; }
    .e-formnewspaper.ee-col-3 { column-count: 3; }
    .e-formnewspaper.ee-col-4 { column-count: 4; }
    }

    /* DASH */
    .e-formdash { align-content: stretch; align-items: flex-start; display: flex; flex-flow: row; font-size: 0; gap: 10px; justify-content: flex-start; margin: 20px 0 0 0; width: 100%; }
    .e-formdash > .e-formcol { align-items: flex-start; display: flex; flex-flow: row; gap: 10px; justify-content: flex-start; padding: 0 !important; width: 100%; }
    .e-formdash > .e-formcol .e-field { display: inline-block; margin: 0; }
    .e-formdash > .e-formcol .e-field label { color: #333; display: inline-block; font-size: 12px; font-weight: 700; left: 5px; line-height: 13px; pointer-events: none; padding: 0 5px; position: absolute; text-transform: uppercase; top: -6px; z-index: 1; }
    .e-formdash > .e-formcol .e-field label:before { background: #fff; content: ''; display: block; height: 7px; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; }
    .e-formdash > .e-formcol > .e-buttontext { display: inline-block; margin: 0; }
    .e-formdash > .e-formcol > .e-buttonrow { display: inline-block; margin: 0; }
    @media screen and (max-width: 719.999px) {
    .e-formdash { flex-flow: row wrap; }
    .e-formdash > .es-width-50 { width: calc(50% - 10px); }
    }

    /* RESULT */
    .e-formresult { align-content: stretch; align-items: center; border-top: 2px solid #333; display: flex; flex-flow: row; font-size: 0; gap: 10px; justify-content: space-between; margin: 20px 0 0 0; padding: 10px 0 0 0; width: 100%; }
    .e-formresult > p { display: inline-block; font-size: 12px !important; margin: 0 !important; }
    .e-formresult ul { display: inline-block; list-style-type: none; margin: 0; padding: 0; text-align: right; }
    .e-formresult ul li { display: inline-block; list-style-type: none; margin: 0 0 0 2px; padding: 0; }
    .e-formresult ul li a { border-radius: 3px; box-shadow: inset 0 0 0 1px #e6e6e6; color: #333; display: block; font-size: 12px; font-weight: 700; line-height: 30px; text-align: center; width: 30px; }
    .e-formresult ul li a:hover { background: #e6e6e6; }
    .e-formresult ul li a.ee-active { background: #333; box-shadow: none; color: #fff; }

    /* TABLE */
    table.e-formtable { border: 0; border-spacing: 0; border-collapse: collapse; margin: 10px 0 0 0; padding: 0; width: 100%; }
    table.e-formtable thead th { background: #333; border: none; color: #fff; font-size: 12px; font-weight: bold; line-height: 20px; padding: 5px; text-transform: uppercase; }
    table.e-formtable thead th:first-child { border-bottom-left-radius: 3px; border-top-left-radius: 3px; }
    table.e-formtable thead th:last-child { border-bottom-right-radius: 3px; border-top-right-radius: 3px; }
    table.e-formtable td { border: none; border-top: 1px solid #e6e6e6; font-size: 14px; font-weight: 300; padding: 5px; }
    table.e-formtable tr:first-child td { border-top: none; }
    table.e-formtable small { font-size: 12px; }

    table.e-formtable tr.ee-null td { color: #ccc; }
    table.e-formtable tr.ee-null a { color: #ccc; text-decoration-color: #ccc; }
    table.e-formtable tr.ee-null .e-formstatus { color: #ccc !important; }
    table.e-formtable .e-buttonbullet.ee-size-m { padding-left: 40px; }

    table.e-formtable.ee-edit td:first-child { padding-left: 0; }
    table.e-formtable.ee-edit td:last-child { padding-right: 0; text-align: right; }
    @media screen and (max-width: 959.999px) {
    table.e-formtable-bidder { display: block; }
    table.e-formtable-bidder thead { display: none; }
    table.e-formtable-bidder tbody { display: block; }
    table.e-formtable-bidder tr { align-items: center; border-top: 1px solid #e6e6e6; display: flex; flex-flow: row wrap; gap: 2px 10px; padding: 10px 0; }
    table.e-formtable-bidder td { box-sizing: border-box; border-top: none; display: inline-block; order: 4; padding: 0; width: 100%; }
    table.e-formtable-bidder td:nth-child(1) { order: 1; width: 30px; }
    table.e-formtable-bidder td:nth-child(2) { order: 3; width: calc(100% - 80px); }
    table.e-formtable-bidder td:nth-child(7) { order: 3; width: 30px; }
    table.e-formtable-bidder td:nth-child(3) { padding-left: 40px; width: 100%; }
    table.e-formtable-bidder td:nth-child(3):empty { display: none; }
    table.e-formtable-bidder td:nth-child(4) { padding-left: 40px; width: 100%; }
    table.e-formtable-bidder td:nth-child(4):empty { display: none; }
    table.e-formtable-bidder td:nth-child(5) { padding-left: 40px; width: calc(100% - 110px); }
    table.e-formtable-bidder td:nth-child(5):before { content: 'Mobile'; display: inline-block; font-size: 12px; margin: 0 5px 0 0; vertical-align: middle; }
    table.e-formtable-bidder td:nth-child(6) { text-align: right; width: 100px; }
    table.e-formtable-bidder td:nth-child(6):before { content: 'Notifcations'; display: inline-block; font-size: 12px; margin: 0 5px 0 0; vertical-align: middle; }
    }
    @media screen and (max-width: 959.999px) {
    table.e-formtable-bidding { display: block; }
    table.e-formtable-bidding  thead { display: none; }
    table.e-formtable-bidding  tbody { display: block; }
    table.e-formtable-bidding  tr { align-items: center; border-top: 1px solid #e6e6e6; display: flex; flex-flow: row wrap; gap: 2px 10px; padding: 10px 0; }
    table.e-formtable-bidding  td { box-sizing: border-box; border-top: none; display: inline-block; order: 4; padding: 0; width: 100%; }
    table.e-formtable-bidding td:nth-child(1) { order: 1; width: calc(100% - 40px); }
    table.e-formtable-bidding td:nth-child(6) { order: 2; width: 30px; }
    table.e-formtable-bidding td:nth-child(2) { padding-left: 40px; width: auto; }
    table.e-formtable-bidding td:nth-child(3) { padding-left: 10px; width: auto; }
    table.e-formtable-bidding td:nth-child(4) { padding-left: 40px; width: 100%; }
    table.e-formtable-bidding td:nth-child(5) { padding-left: 40px; width: 100%; }
    table.e-formtable-bidding td:nth-child(5):empty { display: none; }
    }

    table.e-tableinc { border: 0; border-spacing: 0; border-collapse: collapse; counter-reset: forminc; margin: 10px 0 0 0; padding: 0; text-align: left; width: 100%; }
    table.e-tableinc thead th { border: none; border-top: 1px solid #ccc; color: #333; font-size: 12px; font-weight: bold; line-height: 20px; padding: 5px 0; position: relative; text-transform: uppercase; }

    table.e-tableinc thead th .e-buttontext { display: block; }
    table.e-tableinc td { border: none; padding: 0 0 5px 0; vertical-align: top; }
    table.e-tableinc tr.ee-last td { border-bottom: 1px solid #ccc; border-top: 1px solid #ccc; padding-top: 5px; }
    table.e-tableinc td .e-field { margin-top: 0; width: 100%; }
    table.e-tableinc td .e-buttontext { margin-top: 0; }
    table.e-tableinc td span.ee-limit { border-radius: 5px; box-shadow: inset 0 0 0 1px #e6e6e6; box-sizing: border-box; display: block; font-size: 14px; font-weight: 700; line-height: 40px; margin: 0 !important; padding: 0 10px; width: 100%; }
    table.e-tableinc td:nth-child(1) { padding-right: 10px; }
    table.e-tableinc td:nth-child(4) { padding-right: 0; width: 40px; }
    table.e-tableinc td:nth-child(2), table.e-tableinc td:nth-child(3) { padding-right: 5px; width: 50%; }
    table.e-tableinc tbody td:nth-child(1):before { counter-increment: forminc; content: counter(forminc); font-size: 14px; line-height: 40px; }
    table.e-tableinc tfoot { display: none; }

    /* ALERT */
    .e-alert { background: #e6e6e6; box-shadow: inset 0 0 0 2px #808080; color: rgba(0,0,0,0.8); margin: 20px 0 0 0; min-height: 60px; padding: 10px 10px 10px 60px; position: relative; text-align: left; width: 100%; }
    .e-alert:before { background-color: #808080; border-radius: 50%; content: ''; display: inline-block; height: 40px; margin: 0 10px 0 -50px; vertical-align: top; width: 40px; }
    .e-alert:after { background: transparent url('icon/alert.svg') no-repeat 50% 50%; background-size: 100%; content: ''; display: block; filter: var(--filter-000-fff); height: 20px; left: 20px; position: absolute; top: 20px; width: 20px; }
    .e-alert p { display: inline-block; font-size: 14px; font-weight: 400; margin: 10px 0 0 0 !important; vertical-align: middle; }
    .e-alert p a { font-weight: 700; text-decoration: underline; text-decoration-color: rgba(0,0,0,0.5); }
    .e-alert.ee-error { background: rgba(var(--error-rgb),0.2); box-shadow: inset 0 0 0 2px var(--error-hex); }
    .e-alert.ee-error:before { background-color: var(--error-hex); }
    .e-alert.ee-warning { background: rgba(var(--warning-rgb),0.2); box-shadow: inset 0 0 0 2px var(--warning-hex); }
    .e-alert.ee-warning:before { background-color: var(--warning-hex); }
    .e-alert.ee-success { background: rgba(var(--success-rgb),0.2); box-shadow: inset 0 0 0 2px var(--success-hex); }
    .e-alert.ee-success:before { background-color: var(--success-hex); }
    .e-alert.ee-success:after { background-image: url('icon/tick.svg'); }

    /* POPUP */
    .e-popup { background: rgba(0,0,0,0.8); display: block; font-size: 0; left: 0; min-width: 320px; min-height: 100%; overflow: hidden; position: fixed; top: 0; width: 100%; z-index: 98; }
    .e-popupblock { cursor: pointer; height: 100%; left: 0; overflow: hidden; position: absolute; top: 0; width: 100%; }

    .e-popuppage { background: #fff; left: 50%; padding: 60px 0 10px 0; position: absolute; top: 50%; transform: translateX(-50%) translateY(-50%); width: calc(100% - 40px); }
    .e-popup.ee-width-s .e-popuppage { max-width: 320px; }
    .e-popup.ee-width-m .e-popuppage { max-width: 640px; }
    .e-popup.ee-width-l .e-popuppage { max-width: 960px; }
    .e-popupheader { border-bottom: 1px solid #e6e6e6; left: 0; min-height: 50px; overflow: hidden; padding: 10px 50px 10px 20px; position: absolute; text-align: left; top: 0; width: 100%;}
    .e-popupheader .e-popupclose { background: #333; border-radius: 3px; display: block; font-size: 0; height: 40px; position: absolute; right: 10px; top: 10px; width: 40px; }
    .e-popupheader .e-popupclose:hover { background: #000; }
    .e-popupheader .e-popupclose:before { background: #fff; border-radius: 2px; content: ''; display: block; height: 2px; left: 10px; position: absolute; top: 19px; transform: rotate(45deg); width: 20px; }
    .e-popupheader .e-popupclose:after { background: #fff; border-radius: 2px; content: ''; display: block; height: 2px; left: 10px; position: absolute; top: 19px; transform: rotate(-45deg); width: 20px; }
    .e-popupheader h1 { color: #333; font-size: 18px; line-height: 30px; margin: 5px 0 0 0; text-transform: uppercase; }
    .e-popupmain { margin: 0; max-height: calc(100vh - 160px); overflow-x: hidden; overflow-y: auto; -webkit-overflow-scrolling: touch; padding: 0 20px; position: relative; width: 100%; }
    body.ee-scrolled-s .e-popupmain { max-height: calc(100vh - 120px); }
    /* .e-popupmain:before { content: ''; float: left; height: calc(100% + 1px); margin-left: -1px; width: 1px; } */
    .e-popupmain:after { clear: both; content: ''; display: block; height: 10px; width: 100%; }

    .e-popupimage { background: rgba(0,0,0,0.9); }
    .e-popupimage .e-popupclose { background: rgba(51,51,51,0.8); display: block; font-size: 0; height: 80px; position: absolute; right: 0; top: 0; width: 80px; z-index: 1; }
    .e-popupimage .e-popupclose:before { background: #fff; border-radius: 2px; content: ''; display: block; height: 4px; left: 20px; position: absolute; top: 38px; transform: rotate(45deg); transition: 0.3s; width: 40px; }
    .e-popupimage .e-popupclose:after { background: #fff; border-radius: 2px; content: ''; display: block; height: 4px; left: 20px; position: absolute; top: 38px; transform: rotate(-45deg); transition: 0.3s; width: 40px; }

    .e-popupalert { background: var(--neutral-hex); box-sizing: border-box; color: #fff; display: block; font-size: 12px; font-weight: 700; left: 0; line-height: 20px; min-height: 30px; padding: 5px 10px; position: absolute; text-align: left; text-transform: uppercase; top: -30px; width: 100%; z-index: 1; }
    a.e-popupalert { padding: 5px 40px 5px 10px; }
    a.e-popupalert:after { background: transparent url('image/statusno.svg') no-repeat 50% 50%; background-size: 100%; content: ''; display: block; height: 20px; position: absolute; right: 5px; top: 5px; width: 20px; }
    .e-popupalert.ee-error { background: var(--error-hex); }
    .e-popupalert.ee-warning { background: var(--warning-hex); }
    .e-popupalert.ee-success { background: var(--success-hex); }

    @media screen and (max-width: 599.999px) {
    .e-popuppage { max-height: calc(100% - 10px); width: calc(100% - 10px); }
    .e-popupimage .e-popupclose { height: 40px; right: 0; width: 40px; }
    .e-popupimage .e-popupclose:before, .e-popupimage .e-popupclose:after { left: 5px; top: 18px; width: 30px; }
    }



    /* SPINNER */
    .e-spinner { display: block; height: 60px; margin: 20px 0 0 0; overflow: hidden; position: relative; text-align: center; width: 100%; }
    .e-spinner:after { animation: espinner 1s linear infinite; box-sizing: border-box; border: 10px solid #ccc; border-bottom-color: transparent; border-radius: 50%; content: ''; display: block; height: 60px; left: calc(50% - 30px); position: absolute; top: 0; width: 60px; }
    @keyframes espinner {  0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }





