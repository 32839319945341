/* Importing Bootstrap SCSS file. */
@import 'bootstrap'

/* You can add global styles to this file, and also import other style files */
@import "assets/main.css"
@import "assets/form.css"
@import "assets/fonts/Lato/fonts.css"

*
    font-family: 'Lato', sans-serif

a.disabled
  /* Make the disabled links grayish*/
  color: gray
  /* And disable the pointer events */
  pointer-events: none
  opacity: 0.5
  cursor: default

/* HEADER SCROLLED */
.ea-scrolledheader.ee-scrolled-s main#e-main 
    padding-top: 80px


.ea-scrolledheader.ee-scrolled-s header#e-header 
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1)
    height: 60px
    position: fixed
    top: -60px


.ea-scrolledheader.ee-scrolled-m header#e-header 
    transition: 0.3s


.ea-scrolledheader.ee-scrolled-l header#e-header 
    top: 0


.ea-scrolledheader.ee-scrolled-s header#e-header:after 
    right: 60px
    width: 60px


.ea-scrolledheader.ee-scrolled-s header#e-header:before 
    display: none !important


.ea-scrolledheader.ee-scrolled-s header#e-header a#e-headertitle 
    height: 50px
    padding: 0 10px
    top: 0px
    width: auto !important


.ea-scrolledheader.ee-scrolled-s header#e-header a#e-headertitle:before 
    display: none


.ea-scrolledheader.ee-scrolled-s header#e-header a#e-headertitle img
    display: none


.ea-scrolledheader.ee-scrolled-s header#e-header #e-headerlogin 
    display: none


.ea-scrolledheader.ee-scrolled-s header#e-header a#e-headerhamburger 
    height: 60px
    right: 0
    width: 60px


.ea-scrolledheader.ee-scrolled-s header#e-header a#e-headerhamburger>span.ee-icon 
    height: 30px
    left: 15px
    top: 15px
    width: 30px


.ea-scrolledheader.ee-scrolled-s header#e-header a#e-headerhamburger.ee-active>span.ee-icon:before 
    transform: translateY(9px) rotate(45deg)


.ea-scrolledheader.ee-scrolled-s header#e-header a#e-headerhamburger.ee-active>span.ee-icon:after 
    transform: translateY(-9px) rotate(-45deg)


.ea-scrolledheader.ee-scrolled-s header#e-header a#e-headeruser 
    height: 60px
    right: 60px
    width: 60px


.ea-scrolledheader.ee-scrolled-s header#e-header a#e-headeruser.ee-alert:after 
    right: 13px
    top: 13px


.ea-scrolledheader.ee-scrolled-s header#e-header a#e-headeruser>span.ee-icon 
    height: 30px
    left: 15px
    top: 15px
    width: 30px


.ea-scrolledheader.ee-scrolled-s header#e-header a#e-headeruser>span.ee-text 
    padding-right: 35px
    right: 30px


.ea-scrolledheader.ee-scrolled-s header#e-header a#e-headeruser>span.ee-text small 
    display: none


.ea-scrolledheader.ee-scrolled-s header#e-header a#e-headeruser>span.ee-text strong 
    line-height: 20px


.ea-scrolledheader.ee-scrolled-s header#e-header ul.e-headermenu 
    max-height: calc(100vh - 60px) !important
    overflow-x: hidden
    overflow-y: auto
    top: 60px !important


.ea-scrolledheader.ee-scrolled-s header#e-header #e-headerblock 
    height: calc(100vh - 60px) !important
    position: fixed
    top: 60px !important


@media screen and (max-width: 639.999px) 
    .ea-scrolledheader.ee-scrolled-s main#e-main 
        padding-top: 60px

// Drag & Drop
.drag-list
  max-width: 100%
  display: block
  background: white
  overflow: hidden

.drag-box
  padding: 20px 10px
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  box-sizing: border-box
  cursor: move !important
  background: white

.cdk-drag-preview
  pointer-events: none
  box-sizing: border-box
  box-shadow: 0 5px 5px -3px rgba(85, 44, 44, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12)

.cdk-drag-animating
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1)

.drag-box:last-child
  border: none

.drag-list.cdk-drop-list-dragging .drag-box:not(.cdk-drag-placeholder)
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1)

.drag-placeholder
  background: #ccc
  border: dotted 3px #999
  min-height: 60px
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1)


@media screen and (max-width: 639.999px) 
    a#e-headertitle img
        top: 20%


.ei-linkedin:before
    background-image: url('assets/icon/linkedin.svg')
.ei-whatsapp:before
    background-image: url('assets/icon/whatsapp.svg')
.ei-twitter:before
    background-image: url('assets/icon/twitter.svg')
.ei-facebook:before
    background-image: url('assets/icon/facebook.svg')
.ei-instagram:before
    background-image: url('assets/icon/instagram.svg')
.loading-gif-large
  height: 75px
  width: 75px
  border-radius: 50%
  object-fit: cover